import Layout from '../../Components/Layout/Layout';
import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import LoadingPage from '../../Containers/YSFHomePage/loadingPage';
import { Helmet } from 'react-helmet-async';

const HomePage = lazy(() => import('../../Containers/YSFHomePage/home'));

const AppContainer = styled.div`
	${tw`w-[100vw] min-h-[100vh] bg-[#7552CC]`}
`;

function YSFPage() {
	return (
		<>
			<Helmet>
				<title>Youth Speak Forum - AIESEC in Nigeria</title>
				<meta name="title" content="Youth Speak Forum - AIESEC in Nigeria" />
				<meta name="description" content="TechForward Earth: Empowering Youths for Sustainable Innovation
				Join us in finding tech-oriented solutions to the issues threatening our environment. Together, we’ll create a more sustainable planet." />

				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://aiesec.ng/programs/ysf" />
				<meta property="og:title" content="Youth Speak Forum - AIESEC in Nigeria" />
				<meta property="og:description" content="TechForward Earth: Empowering Youths for Sustainable Innovation
				Join us in finding tech-oriented solutions to the issues threatening our environment. Together, we’ll create a more sustainable planet." />
				<meta property="og:image" content="https://res.cloudinary.com/skycoder/image/upload/v1715801498/21_plvv3a.jpg" />

				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content="https://aiesec.ng/programs/ysf" />
				<meta property="twitter:title" content="Youth Speak Forum - AIESEC in Nigeria" />
				<meta property="twitter:description" content="TechForward Earth: Empowering Youths for Sustainable Innovation
				Join us in finding tech-oriented solutions to the issues threatening our environment. Together, we’ll create a more sustainable planet." />
				<meta property="twitter:image" content="https://res.cloudinary.com/skycoder/image/upload/v1715801498/21_plvv3a.jpg" />
				<link rel="canonical" href="https://aiesec.ng/programs/ysf" />

			</Helmet>
			<Layout>
				<Suspense fallback={<LoadingPage />}>
					<AppContainer>
						<HomePage />
					</AppContainer>
				</Suspense>
			</Layout>
		</>
	);
}

export default YSFPage;
