import { Radio } from '@mui/material';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Button from '../../Components/Button/Button';
import DropdownWithSearch from '../../Components/DropdownWithSearch/DropdownWithSearch';
import Input from '../../Components/Input/Input';
import NvFormLayout from '../../Components/NvFormLayout/NvFormLayout';
import { NvContext } from '../../Context/NvContext';
import { activteToggler } from '../../HelperFunctions/activeToggler';
import classes from './NvStatesForm.module.css';
import { inputChangeHandler } from '../../HelperFunctions/inputFunction';
import { scrollToTheTop } from '../../Utilities/scrollToTop';
import FormSubmitModal from '../../Components/Modals/FormSubmitModal/FormSubmitModal';
import ModalBody from './ModalBody';

const projects = [
  {
    title: 'Capacity strengthening workshop for changemakers',
    description:
      'The project aims to improve the literacy and numeracy skills for young learners in public primary and secondary schools in Nigeria by enhancing the capacity of public school educators in inclusive education and 21st-century teaching methods.',
    isActive: false,
    states: 'abuja',
  },
  {
    title: 'Tech Club project',
    description:
      'The project aims to empower students in 20 schools across 5 area councils in Abuja with essential digital technology skills. ',
    isActive: false,
    states: 'abuja',
  },
  {
    title: 'Building Healthier Communities',
    description:
      'The project aims to address the challenges faced by individuals living with cancer, aligning with Sustainable Development Goal 3 Good Health and Well-being. Through a multi-faceted approach, the project seeks to improve access to quality healthcare services, enhance support systems, and promote awareness and education about cancer prevention and treatment.',
    isActive: false,
    states: 'cross-river',
  },

  {
    title: 'Eduinnovation hub',
    description:
      'The aim of this project is to substantially increase the number of young children and teenagers who have relevant skills, including technical and vocational skill, for employment, decent jobs, and entrepreneurship.',
    isActive: false,
    states: 'cross-river',
  },
  {
    title: 'Helping Hands',
    description:
      'The project aims to contribute to SDG #3 by providing caregiving to sick persons in the hospital, helping in daily operation of the hospital and assisting the medical personnel in the facility with daily routines.',
    isActive: false,
    states: 'benue',
  },
  {
    title: 'Teach the Child',
    description:
      'The project aim is to provide children and young people with quality and easy access to education plus other learning opportunities, to achieve universal literacy and numeracy. A major component in acquiring knowledge and valuable skills in the learning environment.',
    isActive: false,
    states: 'benue',
  },
  {
    title: 'Edurural',
    description:
      "Volunteers are to work closely with our partners in reaching schools found lacking in certain fields of knowledge where they are understaffed or lack efficient teachers in relevant subjects necessary for the development of the Child's mind.",
    isActive: false,
    states: 'edo',
  },
  {
    title: 'Skill Up',
    description:
      'The volunteer here is to enlighten unskilled individuals through a platform provided by Partners on several skills or professions that can be used as a reliable source for reducing their financial constraints.',
    isActive: false,
    states: 'edo',
  },
  {
    title: 'Skill Up',
    description:
      'The volunteer here is to enlighten unskilled individuals through a platform provided by Partners on several skills or professions that can be used as a reliable source for reducing their financial constraints.',
    isActive: false,
    states: 'enugu',
  },
  {
    title: 'Edify',
    description:
      'This project aims to provide strong support for child education and promote SDG4 which is quality education',
    isActive: false,
    states: 'enugu',
  },
  {
    title: 'YOUTH AGRI-PIONEEERS',
    description:
      'Seeking sustainable solutions to end hunger in all its forms and to achieve food security, improved nutrition and promote sustainable agriculture to ensure that everyone everywhere has enough good-quality food to lead a healthy life.',
    isActive: false,
    states: 'enugu',
  },
  {
    title: 'Inclusive Ed',
    description:
      'Volunteers assisting the teachers in shearing notes, teaching and carrying verbal education with the students. Volunteers make use of technology (YouTube, drawings) to teach children living with disabilities. Volunteers make use of films to education the children. Volunteers bring about gaming activities to engage the children.',
    isActive: false,
    states: 'enugu',
  },

  {
    title:
      'Promoting Accountable Governance for Effective Service Delivery (PAGES)',
    description:
      'This project aims to contribute to SDG #10 by promoting policies that prioritize the needs, rights, and well-being of women and youth, creating policies that effectively manage palliatives, and to pass social protection bills for women and children.',
    isActive: false,
    states: 'enugu',
  },

  {
    title: 'Vest Up',
    description:
      'This project aims to accomplish SDG 8 (decent work and economic growth) by facilitating clubs in schools that are about building knowledge on finance, investment and enterprenureship which would develop the financial capacity of young people.',
    isActive: false,
    states: 'enugu',
  },

  {
    title: 'FOR THE HEALTH OF IT',
    description:
      'This project aims to ensure healthy living and promote well-being for all ages',
    isActive: false,
    states: 'enugu',
  },

  {
    title: 'HIV Hotline and Counselling',
    description:
      'A telephone hotline project that involves receiving calls from people all over the country who need information about HIV/AIDS. The goal is to provide factual, helpful information and possibly connect them to the help they need. it also caters to people who need counselling on how to take tests, go for checkups and more.',
    isActive: false,
    states: 'lagos',
  },

  {
    title: 'Rural Child Education',
    description:
      'This project aims to help to educate the kids in low-income communities with basic numearcy and literacy knowledge.',
    isActive: false,
    states: 'ogun',
  },
  {
    title: 'Scale Up',
    description:
      'The project aims to contribute to SDG #17 by building up the capacity of local NGO, non-profit organizations and/or foundations to contribute to social impact in their communities by collaborating with local social projects and engaging with grass-groot initiatives.',
    isActive: false,
    states: 'ondo',
  },

  {
    title: 'Educating a Child',
    description:
      'The project is aimed at incorporating extracurricular activities which includes sex education, career/leadership development and debate into secondary school’s curriculum ',
    isActive: false,
    states: 'osun',
  },

  {
    title: 'Training Stars',
    description: `This project seeks to advance SDG 4 & 6 by creating a learning space to further teach and engage the orphanage homes with curricular and extra-curricular topics i.e, entrepreneurship, digital skills, and other economically productive crafts. The project is also aimed at creating awareness about the "why" and "how" to ensure the availability and sustainable management of water and proper sanitation.`,
    isActive: false,
    states: 'osun',
  },
  {
    title: 'Empower Together',
    description: `This project aims to leverage on the skills and dedication of volunteers to support educational  initiatives for children, provide financial aid through sponsors and donations and meet the basic needs of underprivileged communities. With this project, volunteers contribute towards the NGO cause of empowering children, youth and the community at large.`,
    isActive: false,
    states: 'oyo',
  },

  {
    title: 'Young Pilllars',
    description: `The project seeks to provide youths and children with quality education and relevant skills for growth and sustainability and the awareness to effect positive change in their environment.`,
    isActive: false,
    states: 'plateau',
  },
  {
    title: 'Be More',
    description: `The project is aimed at educating children on entrepreneurial skills and other related areas to enable them take advantage of vast opportunities around them.`,
    isActive: false,
    states: 'plateau',
  },

  {
    title: 'Healthy Living',
    description: `This project aims to teach children basic healthy habits, personal hygiene and the right measures to take in cases of emergencies.`,
    isActive: false,
    states: 'rivers',
  },
  {
    title: 'Skillify',
    description: `This project aims to provide children with holistic learning experiences by focusing on teaching craft and handy skills. The initiative seeks to enhance creativity and practical knowledge, giving children hands-on experience in various crafts.`,
    isActive: false,
    states: 'rivers',
  },
  {
    title: 'Edu-Next-Gen',
    description: `This project aims to promote quality Education in the field of Economics & Data Processing amongst children and empower them to make impact.`,
    isActive: false,
    states: 'rivers',
  },
  {
    title: 'Green Initiative',
    description: `The project  aims at promoting  sustainable practices like  plastic recycling, and educating the communities on climate change.`,
    isActive: false,
    states: 'kwara',
  },
];

const NvStatesForm = () => {
  // Router
  const { city } = useParams();
  const navigate = useNavigate();

  // Context
  const { nvSignUpForm, setNvSignUpForm, nvSignupSubmit, submitNvSignUpForm } =
    useContext(NvContext);

  // States
  const [activeProjects, setActiveProjects] = useState([]);
  const [healthIssues, setHealthIssues] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Utils
  let selectedProject = activeProjects.find((data) => {
    return data.isActive;
  });

  // Effects
  useEffect(() => {
    if (city) {
      setActiveProjects(
        projects?.filter((data) => {
          return data.states === city;
        })
      );
    }

    // eslint-disable-next-line
  }, [city]);

  useEffect(() => {
    if (nvSignUpForm?.health_issues) {
      setHealthIssues(nvSignUpForm?.health_issues);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedProject) {
      setNvSignUpForm((prevState) => {
        return { ...prevState, preferred_project: selectedProject?.title };
      });
    }

    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    if (healthIssues) {
      setNvSignUpForm((prevState) => {
        return { ...prevState, health_issues: healthIssues };
      });
    }

    // eslint-disable-next-line
  }, [healthIssues]);

  useEffect(() => {
    if (nvSignupSubmit?.data) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }

    console.log(nvSignupSubmit?.data);

    // eslint-disable-next-line
  }, [nvSignupSubmit?.data]);

  return (
    <NvFormLayout notShowFooter>
      {showModal && (
        <FormSubmitModal
          body={<ModalBody />}
          onClick={() => {
            setShowModal(false);
          }}
        />
      )}

      <form>
        <h4>{city?.replaceAll('-', ' ').toUpperCase()}</h4>

        {activeProjects?.map((data, i) => {
          return (
            <div className={classes.preferredProject} key={data.title}>
              <p>Choose your preferred project</p>
              <p>{data.description}</p>

              <div
                onClick={() => {
                  activteToggler(i, activeProjects, setActiveProjects);
                }}
              >
                <label htmlFor={data.title}>{data.title}</label>
                <Radio
                  size="medium"
                  name={data.title}
                  id={data.title}
                  checked={data.isActive}
                />
              </div>
            </div>
          );
        })}

        <div className={classes.formSection}>
          <DropdownWithSearch
            options={['Yes', 'No']}
            title="Select"
            label="Do you have any health issues?*"
            selected={healthIssues || nvSignUpForm?.health_issues}
            setSelected={setHealthIssues}
          />
          {healthIssues === 'Yes' && (
            <Input
              label="If yes, please specify "
              placeholder="Please specify"
              name="please_specify"
              onChange={(e) => {
                inputChangeHandler(e, setNvSignUpForm);
              }}
              value={nvSignUpForm.please_specify}
              readOnly={healthIssues === 'No' || false}
            />
          )}
          <Input
            label="In cases of emergency, who do we contact?"
            placeholder="Type contact name and relationship with this contant"
            tip="Kindly specify your relationship with this person"
            name="who_do_we_contact"
            onChange={(e) => {
              inputChangeHandler(e, setNvSignUpForm);
            }}
            value={nvSignUpForm.who_do_we_contact}
          />

          <Input
            label="Contact Information of Emergency contact"
            placeholder="Type contact information"
            name="emergency_contact"
            onChange={(e) => {
              inputChangeHandler(e, setNvSignUpForm);
            }}
            value={nvSignUpForm.emergency_contact}
          />

          <Input
            label="Any thing else you'd like us to know?"
            placeholder="Tell us anything else we should know"
            name="anything_else_to_know"
            onChange={(e) => {
              inputChangeHandler(e, setNvSignUpForm);
            }}
            value={nvSignUpForm.anything_else_to_know}
          />
        </div>

        {nvSignupSubmit?.error && (
          <p className={classes.error}>{nvSignupSubmit?.error}</p>
        )}

        <div className={classes.buttonSection}>
          <Button
            subClass="nv"
            type="secondary"
            noRadius
            onClick={(e) => {
              e.preventDefault();
              scrollToTheTop();
              navigate('/programs/nv/sign-up');
            }}
          >
            Previous
          </Button>

          <Button
            noRadius
            subClass="nv"
            disabled={
              !nvSignUpForm?.preferred_project ||
              !nvSignUpForm?.health_issues ||
              !nvSignUpForm?.who_do_we_contact ||
              !nvSignUpForm?.emergency_contact
            }
            onClick={(e) => {
              e.preventDefault();
              submitNvSignUpForm();
            }}
            loading={nvSignupSubmit?.isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
    </NvFormLayout>
  );
};

export default NvStatesForm;
