import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import classes from "./LandingPageHeroSection.module.css";



const LandingPageHeroSection = () => {

  return (
    <div className={classes.container}>
      <h4>
        Connecting <span>Young People</span> <br /> to Life Changing <br />{" "}
        <span>Experiences.</span>
      </h4>
      <div className={classes.inputSection}>
        {/* <div>
          <input
            type="text"
            placeholder="Search for an experience. E.g. Marketing, design, HR..."
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18.677 19.6069L12.962 13.8909C10.4197 15.6984 6.91643 15.2562 4.90287 12.8738C2.8893 10.4914 3.03715 6.96349 5.243 4.7579C7.44826 2.55134 10.9765 2.40285 13.3594 4.41631C15.7422 6.42977 16.1846 9.93334 14.377 12.4759L20.092 18.1919L18.678 19.6059L18.677 19.6069ZM9.485 4.99988C7.5887 4.99946 5.95269 6.33057 5.56747 8.18733C5.18225 10.0441 6.15371 11.9162 7.89367 12.6701C9.63364 13.4241 11.6639 12.8527 12.7552 11.3019C13.8466 9.75117 13.6991 7.64721 12.402 6.2639L13.007 6.8639L12.325 6.1839L12.313 6.1719C11.5648 5.41907 10.5464 4.99702 9.485 4.99988Z"
              fill="#037EF3"
            />
          </svg>
        </div> */}
        <div></div>
      </div>{" "}
      {/* <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={""}
        className='lg:w-1/2 w-[90%]'
        footer={[
          <Button className="rounded-[1.5rem] italic h-[40px] px-6 py-2" key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button className="bg-main rounded-[1.5rem] italic h-[40px] px-6 py-2 text-white font-bold" key="link" type="primary">
            <Link to="/programs/ysf" onClick={() => setIsModalVisible(false)}>
              Learn More
            </Link>
          </Button>
        ]}
      >
        <div className="hover:transform-none my-4 w-full h-[full]">
          <img
            className="w-[full] h-[full] object-contain"
            src='https://res.cloudinary.com/skycoder/image/upload/v1716277382/Frame73_tbz12k.png'
            alt='Hero banner'
          />
        </div>
      </Modal> */}
      <div className={classes.videoContainer}>
        <VideoPlayer
          height="335px"
          url="https://res.cloudinary.com/dmpdhnjqs/video/upload/v1697635153/TikTok_Video_b6ioek.mp4"
        // url="https://drive.google.com/file/d/1BT9FuSCMe88MimWIK_zIN_UM9nxTSFhB/view?pli=1"
        />
      </div>

    </div>
  );
};

export default LandingPageHeroSection;
